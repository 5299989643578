import React from 'react'
import Employer from './navbar/Employer';

const Home = () => {
  return (
    <div className='app'>
      <Employer />
    </div>
  );
};

export default Home