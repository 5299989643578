import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import logo from "../../assets/dedukt logo.svg";
import logoo from "../../assets/deduktlogoo.svg";
import deduktheader from "../../assets/deduktheader.svg";
import logo1 from "../../assets/logo1.svg";
import logo2 from "../../assets/logo2.svg";
import logo3 from "../../assets/logo3.svg";
import logo4 from "../../assets/logo4.svg";
import achieve from "../../assets/achieveimg.svg";
import access from "../../assets/accessimg.svg";
import smallScreen from "../../assets/smallscreen.svg";
import bigScreen from "../../assets/bigscreen.svg";
import buildd from "../../assets/buildd.svg";
import privicon from "../../assets/privacyicon.png";
import linkedin from "../../assets/LinkedIn.svg";
import Deduktabout from "../../assets/Deduktabout.svg";
//import CountUp from 'react-countup';
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import AOS from "aos";
import numeral from "numeral";
import axios from "axios";
import "aos/dist/aos.css";

const Lender = () => {
  const url = process.env.REACT_APP_URL;
  const [signUpModal, setSignUpModal] = useState(false);
  const [reqDemoModal, setReqDemoModal] = useState(false);
  const [privacyDemoModal, setPrivacyDemoModal] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [employees, setEmployees] = useState(0);
  const [lenders, setLender] = useState(0);
  const [isEmployerActive, setIsEmployerActive] = useState(true);
  const [deduction, setDeduction] = useState(0);
  const extraContent = (
    <div className='mt-4'>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        We may change this Privacy Policy from time to time. If we make changes,
        we will notify you by revising the date at the top of this policy, and
        in some cases, we may provide you with additional notice (such as by
        adding a statement to the homepages of our website or by sending you an
        email notification).
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        We encourage you to review the Privacy Policy whenever you interact with
        us to stay informed about our information practices and the ways you can
        help protect your privacy.
      </p>
      <h1 className='mt-4 font-CamptonBold font-bold text-left text-black text-lg'>
        COLLECTION OF INFORMATION
      </h1>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        We may collect and process the following information about you:
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • Information that you provide to us, for example when you fill out a
        contact or web form, or if you register to receive alerts or updates.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • When you provide your Personal Data while signing up for a service or
        purchasing a product.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • Personal Data that we obtain or learn, such as information about the
        browser or device you use to access this site, how you use this site and
        the pages you visit, traffic and location data.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • When you contact our customer support whether by phone, email, or chat
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • [We may also ask you for information if you experience problems when
        using this site. We may also ask you to complete surveys for research
        purposes, although you don’t have to respond to these].
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • [Personal Data we receive from other sources: We are working closely
        with third parties. We will notify you when we receive Personal Data
        about you from them and the purposes for which we intend to use that
        Personal Data].
      </p>

      <h1 className='mt-4 font-CamptonBold font-bold text-left text-black text-lg'>
        USE OF PERSONAL DATA
      </h1>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        We may use your Personal Data, including non-public Personal Data as
        follows:
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        i. Provide, maintain, and improve our services
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        ii. Provide and deliver the products and services you request, process
        transactions and send you related information, including confirmations.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        iii. Verify your identity and prevent fraud.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        iv. Send you technical notices, updates, security alerts and support and
        administrative messages.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        v. Respond to your comments, questions and requests and provide customer
        service.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        vi. Communicate with you about products, services, offers, promotions,
        rewards, and events offered by Equal Digital Limited and others and
        provide news and information we think will be of interest to you.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        vii. Monitor and analyse trends, usage and activities in connection with
        our services.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        viii. Personalize and improve the services and provide advertisements,
        content or features that match user profiles or interests.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        ix. [Process and deliver contest or promotion entries and rewards].
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        x. Link or combine with information we get from others to help
        understand your needs and provide you with better service.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        xi. Carry out any other purpose for which the Personal Data was
        collected.
      </p>

      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        Equal Digital Limited is based in Nigeria and the information we collect
        is governed by the Nigeria Data Protection Act (NDPA) and Regulations
        made thereto and Nigeria Data Protection Regulation (NDPR) as well as
        other regulations made hereunder; (together “Nigeria Data Protection
        Laws"). By accessing or using the Services or otherwise providing
        information to us,you consent to the processing and transfer of
        information in and to Nigeria [and other countries].
      </p>

      <h1 className='mt-4 font-CamptonBold font-bold text-left text-black text-lg'>
        DISCLOSURE OF INFORMATION
      </h1>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        Equal Digital Limited only discloses Personal Data with other companies
        or individuals in the following limited circumstances:
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • We may provide such Personal Data to affiliated companies or other
        trusted businesses or persons for the purpose of processing Personal
        Data on our behalf. We require that these parties agree to process such
        Personal Data based on our instructions and in compliance with the
        Nigerian Data Protection Laws and any other appropriate confidentiality
        and security measures. When they no longer need your Personal Data to
        fulfil this service, they will dispose of the details in line with the
        Nigerian Data Protection Laws .
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • [With other third-parties such as email service providers that perform
        marketing services on [Name of Company]’s behalf].
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • With other non-affiliated companies for our everyday business
        purposes, such as to process transactions, maintain accounts, respond to
        court orders and legal investigations.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • In response to a request for Personal Data, if we are required by, or
        we believe disclosure is in accordance with, any applicable law,
        regulation or legal process.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • With relevant law enforcement officials or other third parties, such
        as investigators or auditors, if we believe it is appropriate.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • In connection with, or during negotiations of, any merger, sale of
        Equal Digital Limited assets, financing or acquisition of all or a
        portion of our business to another company; and
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • With your consent or at your direction, including if we notify you
        that the Personal Data you provide will be shared in a particular manner
        and you provide such Personal Data.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • We may also share aggregated or de-identified or anonymized
        Information, which cannot reasonably be used to identify you.
      </p>

      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        When Equal Digital Limited does not have a lawful basis for disclosure
        of your Personal Data, Equal Digital Limited will obtain consent from
        you before sharing your Personal Data with third parties. Where Equal
        Digital Limited need to transfer your Personal Data to another country,
        such country must have an adequate data protection law. We will seek
        your consent where we need to send your Personal Data to a country
        without an adequate data protection law.
      </p>

      <h1 className='mt-4 font-CamptonBold font-bold text-left text-black text-lg'>
        SECURITY
      </h1>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        Equal Digital Limited takes reasonable measures to help protect all
        Personal Data about you from loss, theft, misuse and unauthorized
        access, disclosure, alteration and destruction. Additionally, Equal
        Digital Limited implements policies designed to protect the
        confidentiality and security of your Personal Data, including a Data
        Protection Policy. [We have also taken measures to comply with provision
        of security facilities for the protection of your Personal Data through
        the set-up of firewalls, limited access to specified authorized
        individuals, encryption and continuous capacity building for relevant
        personnel. We therefore have digital and physical security measures to
        limit and eliminate possibilities of data privacy breach incidents].
      </p>

      <h1 className='mt-4 font-CamptonBold font-bold text-left text-black text-lg'>
        HOW LONG WE KEEP YOUR PERSONAL DATA
      </h1>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        We will hold your Personal Data on Equal Digital Limited systems for as
        long as it is necessary to fulfil the purpose for which it was collected
        or to comply with legal, regulatory or internal policy requirements.
      </p>

      <h1 className='mt-4 font-CamptonBold font-bold text-left text-black text-lg'>
        WHERE WE STORE YOUR PERSONAL DATA
      </h1>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        The Personal Data Equal Digital Limited collects from you may be
        transferred to and stored at a destination outside Nigeria. By
        submitting your Personal Data, you agree to this transfer, storing or
        processing. We will take all steps reasonably necessary to ensure that
        your Personal Data is treated securely with an appropriate level of
        protection and that the transfer is lawful.
      </p>

      <h1 className='mt-4 font-CamptonBold font-bold text-left text-black text-lg'>
        YOUR RIGHTS AS A DATA SUBJECT
      </h1>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        The law gives you certain rights in respect to your Personal Data Equal
        Digital Limited holds about you. Below is a highlight of some of those
        rights. At any point while we are in possession of or processing your
        Personal Data, you, the Data Subject has the following rights:
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • RIGHT OF ACCESS – You have the right to request a copy of the
        information that we hold about you. We will respond to your access
        request within one-month of receiving your request. Where we are not
        able to provide this personal data to you within the One-Month timeline
        provided by the Nigerian Data Protection Laws, we will communicate same
        to you, and may request an additional time within which we will provide
        the information to you. Your Personal Data shall be provided to you in a
        structured, commonly used and machine-readable format.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • RIGHT TO RECTIFY – You have the right to correct the Personal Data we
        hold about you that is inaccurate.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • RIGHT TO BE FORGOTTEN – In certain circumstances you may ask for the
        data we hold about you to be erased from our record.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • RIGHT TO RESTRICT PROCESSING – Where certain conditions apply, you
        have a right to restrict processing of your Personal Data.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • RIGHT TO PORTABILITY – You have the right to have your Personal Data
        transferred to another organisation.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • LODGE COMPLAINT – You have a right to lodge a complaint about the
        handling of your Personal Data with the Nigeria Data Protection
        Commission (NDPC) at{" "}
        <a href='https://ndpc.gov.ng/'>(https://ndpc.gov.ng/)</a>
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        • RIGHT TO OBJECT – You have the right to object to the Processing of
        Personal Data.
      </p>

      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        NDPC’s website <a href='https://ndpc.gov.ng/'>(https://ndpc.gov.ng/)</a>
        has a wealth of useful information in respect of your rights over your
        Personal Data. If you wish to exercise your rights, you may contact our
        Data Protection Officer at dfatunde@dedukt.co or you may write to us at
        101233
      </p>

      <h1 className='mt-4 font-CamptonBold font-bold text-left text-black text-lg'>
        BREACH/ PRIVACY VIOLATION
      </h1>

      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        In the event of a breach of security leading to the accidental or
        unlawful destruction, loss, alteration, unauthorized disclosure of, or
        access to Personal Data, Equal Digital Limited shall within 72
        (Seventy-Two) hours of having knowledge of such breach report the
        details of the breach to the Commission.
      </p>
      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        Furthermore, where we ascertain that such breach is detrimental to your
        rights and freedoms in relation to your Personal Data, we shall within 7
        (Seven) days of having knowledge of the occurrence of such breach take
        steps to inform you of the breach incident, the risk to your rights and
        freedoms resulting from such breach and any course of action to remedy
        said breach.
      </p>

      <h1 className='mt-4 font-CamptonBold font-bold text-left text-black text-lg'>
        QUESTIONS OR CONCERNS
      </h1>

      <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
        If you have any questions or concerns about this Privacy Policy or would
        like to contact us for any reason, you can contact us at
        support@dedukt.co
      </p>
    </div>
  );
  const linkName = readMore ? "Read Less << " : "Read More... ";

  const [messageData, setMessageData] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    axios.get("https://app.dedukt.co/api/opens/stats").then((res) => {
      setEmployees(res.data.data.employees);
      setDeduction(res.data.data.deductions);
      setLender(res.data.data.lenders);
    });
  }, []);

  const date = new Date().getFullYear();

  const toggleModal = (e) => {
    e.preventDefault();
    setSignUpModal(!signUpModal);
  };

  const clickModal = (e) => {
    e.preventDefault();
    setReqDemoModal(!reqDemoModal);
  };

  const selectModal = (e) => {
    e.preventDefault();
    setPrivacyDemoModal(!privacyDemoModal);
  };

  const messageOnchange = (type, text) => {
    switch (type) {
      case "name":
        return setMessageData({ ...messageData, name: text });
      case "email":
        return setMessageData({ ...messageData, email: text });
      case "message":
        return setMessageData({ ...messageData, message: text });
      default:
        return messageData;
    }
  };

  const mailInfo = () => {
    return window.open(
      `mailto:support@dedukt.co?subject=Message from ${messageData.name}&body=${messageData.message}&from=${messageData.email}`
    );
  };
  const sendEmail = async (e) => {
    try {
      e.preventDefault();
      await mailInfo();
      //await alert("Email sent")
    } catch (error) {
      alert(error);
    }
  };
  return (
    <Disclosure as='body' className='bg-white'>
      {({ open }) => (
        <>
          {/* ==========================NAVBAR STARTS================================*/}
          <div
            className='w-screen h-[80px] navbutn bg-white z-10 p-xl-2 p-lg-2 p-md-2 p-sm-0 mt-0 fixed top-0 drop-shadow-sm mx-auto px-4 
          sm:px-6 xl:px-8 lg:px-0'
          >
            <div className='flex items-center justify-center h-full w-full'>
              <Link className='flex-shrink-0' to='/'>
                <img
                  className='block h-full pr-24 w-full xl:pl-12 xl:pr-8 lg:pr-6 lg:pl-12 sm:px-0 dedlogo'
                  src={logo}
                  alt='dedukt'
                />
              </Link>
              <div className='lg:flex md:flex xl:flex hidden lg:space-x-2 xl:space-x-2 md:space-x-2'>
                <a
                  href='/'
                  spy={"true"}
                  smooth={"true"}
                  offset={-70}
                  duration={500}
                  exact='true'
                  onClick={() => {
                    setIsEmployerActive(true);
                  }}
                  className={
                    !isEmployerActive
                      ? "text-transparent bg-clip-text navsizing bg-gradient-to-r xs:hidden from-dedukt-bl to-deduktlg:px-1 px-8 py-3 md:px-1 rounded-md text-base font-medium font-CamptonMedium emp"
                      : "text-transparent bg-clip-text navsizing bg-black lg:px-1 px-8 py-3 md:px-1 rounded-md text-base font-medium font-CamptonMedium emp"
                  }
                >
                  Employer
                </a>
                <div className='border-l-2 border bord border-gray'></div>
                <a
                  href='/lender'
                  spy={"true"}
                  smooth={"true"}
                  offset={-70}
                  duration={500}
                  exact='true'
                  onClick={() => {
                    setIsEmployerActive(false);
                  }}
                  className={
                    isEmployerActive
                      ? "text-transparent bg-clip-text navsizing bg-gradient-to-r xs:hidden from-dedukt-bl to-deduktlg:px-1 px-8 py-3 md:px-1 rounded-md text-base font-medium font-CamptonMedium emp"
                      : "text-transparent bg-clip-text navsizing bg-black lg:px-1 px-8 py-3 md:px-1 rounded-md text-base font-medium font-CamptonMedium emp"
                  }
                >
                  Lender
                </a>
              </div>
              <div className='flex items-center'>
                <div className='hidden navspacing navspcing xl:ml-48 sm:block md:flex md:justify-between sm:ml-6'>
                  <div className='flex space-x-4'>
                    <a
                      href='#aboutus'
                      spy={"true"}
                      smooth={"true"}
                      offset={-70}
                      duration={500}
                      exact='true'
                      className='text-black abtpadding navsizing navspcing lg:px-1 px-8 py-3 md:px-1 
                      rounded-md text-base font-medium font-CamptonMedium'
                    >
                      About us
                    </a>
                    <a
                      href='#howworks'
                      spy={"true"}
                      smooth={"true"}
                      offset={-70}
                      duration={500}
                      exact='true'
                      className='text-black lg:px-1  px-8 py-3 md:px-1 
                      rounded-md text-base font-medium navsizing navspcing font-CamptonMedium'
                    >
                      How It works
                    </a>
                    <a
                      href='#support'
                      spy={"true"}
                      smooth={"true"}
                      offset={-70}
                      duration={500}
                      exact='true'
                      className='text-black lg:px-1  px-8 py-3 md:px-1 
                      rounded-md text-base font-medium navsizing navspcing font-CamptonMedium'
                    >
                      Get Support
                    </a>

                    <a
                      href={url + "login"}
                      spy={"true"}
                      target='_blank'
                      rel='noreferrer'
                      smooth={"true"}
                      offset={-70}
                      duration={500}
                      exact='true'
                      className='inline-flex mr-6 navsizing navspcing items-center px-4 py-2  
                     text-base font-normal rounded-md
                    font-CircularStd-Book text-black'
                    >
                      Sign In
                    </a>
                    <a
                      href='/'
                      onClick={toggleModal}
                      spy={"true"}
                      smooth={"true"}
                      offset={-70}
                      duration={500}
                      exact='true'
                      className=' inline-flex mr-16 items-center px-8 py-3 navsizing navspcing border 
                    bg-gradient-to-r from-dedukt-bl to-dedukt-br text-base font-normal rounded 
                    font-CircularStd-Book text-white'
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div className='flex md:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex men-icon items-center justify-center rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <MenuIcon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className='sm:hidden'>
            <div className='px-2 navsmall pt-2 pb-3 space-y-1'>
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              <Disclosure.Button
                as='a'
                href='/'
                className='text-black block px-3 py-2 rounded-md text-base font-medium 
                font-CamptonMedium'
              >
                Employer
              </Disclosure.Button>
              <Disclosure.Button
                as='a'
                href='/lender'
                className='text-black block px-3 py-2 rounded-md text-base font-medium 
                font-CamptonMedium'
              >
                Lender
              </Disclosure.Button>
              <Disclosure.Button
                as='a'
                href='#aboutus'
                className='text-black block px-3 py-2 rounded-md text-base font-medium 
                font-CamptonMedium'
              >
                About us
              </Disclosure.Button>
              <Disclosure.Button
                as='a'
                href='#howworks'
                className='text-black block px-3 py-2 rounded-md text-base font-medium 
                font-CamptonMedium'
              >
                How It works
              </Disclosure.Button>
              <Disclosure.Button
                as='a'
                href='#support'
                className='text-black block px-3 py-2 rounded-md text-base font-medium 
                font-CamptonMedium'
              >
                Get Support
              </Disclosure.Button>
              <a
                href={url + "login"}
                target='_blank'
                rel='noreferrer'
                className='inline-flex mr-6 items-center text-black px-3 py-2 
                    rounded-md text-base font-medium 
                font-CamptonMedium'
              >
                Sign In
              </a>
            </div>
            <div>
              <div className='pt-2 pb-2'>
                <div className='px-4'>
                  <a
                    href='/'
                    onClick={toggleModal}
                    type='button'
                    className=' inline-flex items-center pb-2 px-3 py-2 border bg-gradient-to-r from-dedukt-bl to-dedukt-br text-base font-normal rounded 
                      font-CircularStd-Book text-white'
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </Disclosure.Panel>
          {/* ==========================NAVBAR ENDS================================*/}
          {/* ==========================HERO SECTION STARTS ============================= */}

          <div
            className='row m-lg-5 p-lg-5 m-md-5 m-sm-2 mt-5 mt-sm-5'
            data-aos='fade-up-down'
          >
            <div className='col-lg-6 col-sm-12 mt-5 col-xs-12 mt-sm-5 h-100 pt-5'>
              <div className=''>
                <h1
                  className='text-5xl font-CamptonBold text-black font-bold m-lg-4 m-md-4 m-sm-1
                sm:text-2xl md:text-3xl lg:text-5xl tracking-tight xl:text-5xl herofirst text-lg-left text-md-center text-sm-center text-xs-center font-size-sm'
                >
                  Get more customers for your loans or savings products across
                  Africa
                </h1>
                <p
                  className='mt-4 text-base herosecond font-CamptonLight text-black m-lg-4 m-md-4 
                sm:text-xs md:mt-4 lg:text-base xl:text-base md:max-w-3xl text-lg-left text-md-center text-sm-center text-xs-center'
                >
                  Dedukt helps connect traditional banks or fintechs to
                  employers and simplifies the process of repaying loans, <br />
                  or saving directly from income.
                </p>
                <div className='mt-3 sm:flex d-flex justify-content-md-center justify-content-sm-center justify-content-lg-start justify-content-xs-start p-lg-4 p-md-4 '>
                  <div className='rounded-md'>
                    <a
                      href='/'
                      onClick={toggleModal}
                      className='w-full flex items-center justify-center px-4 py-4 border
                   text-lg text-white startedbtnn font-normal bg-gradient-to-r 
                   from-dedukt-bl to-dedukt-br rounded md:py-4 md:text-lg lg:text-lg'
                    >
                      Get started for free
                    </a>
                  </div>
                </div>

                <div className='mt-3 sm:flex sm:justify-center lg:justify-start text-center p-lg-4 p-md-4 '>
                  <div className='relative max-w-7xl'>
                    <div className='max-w-4xl mx-auto'>
                      <dl
                        className='rounded bg-gradient-to-r px-8 py-4 gap-10 from-dedukt-gl
                       to-dedukt-gr shadow sm:grid sm:grid-cols-3'
                      >
                        <div className='flex flex-col border-b countbodd border-dedukt-blue p-4 ml-lg-8 text-center sm:border-0'>
                          <dt className='order-2 mt-2 text-sm leading-3 font-medium font-CamptonMedium text-dedukt-blue'>
                            Employees
                          </dt>
                          <dd className='order-1 text-2xl font-bold text-dedukt-blue font-CamptonBold'>
                            {numeral(employees).format("0a")}+
                          </dd>
                        </div>
                        <div
                          className='flex flex-col border-t border-b countbod border-dedukt-blue p-4 text-center 
                        sm:border-0 sm:border-l-2 ml-2.5'
                        >
                          <dt className='order-2 mt-2 text-sm leading-3 font-medium font-CamptonMedium text-dedukt-blue'>
                            Transactions
                          </dt>
                          <dd className='order-1 text-2xl font-bold text-dedukt-blue font-CamptonBold'>
                            {/*<CountUp start={0} end={50000} duration={3} />+*/}
                            {numeral(deduction).format("0a")}+
                          </dd>
                        </div>
                        <div className='flex flex-col border-t countbod border-dedukt-blue p-4 text-center sm:border-0 sm:border-l-2'>
                          <dt
                            className='order-2 mt-2 text-sm text-dedukt-bluetext-sm leading-3 
                          font-medium font-CamptonMedium text-dedukt-blue'
                          >
                            Partners
                          </dt>
                          <dd className='order-1 text-2xl font-bold text-dedukt-blue font-CamptonBold'>
                            {numeral(lenders).format("0a")}+
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-sm-0 d-sm-none d-md-none d-lg-block mt-5'>
              <img
                className='h-100 float-right'
                src={deduktheader}
                alt='header'
              />
            </div>
          </div>

          {/* ==========================HERO SECTION ENDS ============================= */}
          {/* ==========================ACHIEVE STARTS ============================= */}
          {/*access part*/}
          <div className='py-48 achieveecont'>
            <div className=''>
              <div className='row achievespacee'>
                <div className='col-md-6 relative achieveround -ml-20 bg-gradient-to-r from-dedukt-bl to-dedukt-br'>
                  <div
                    className='img-fluid img-responsive d-flex 
                  '
                  >
                    <img
                      alt='achieve'
                      src={achieve}
                      className='w-11/12 justify-center h-full'
                    />
                  </div>
                </div>
                <div className='col-md-4 relative py-4 justify-content-center accessb access-rgg'>
                  <div className='py-4 accessbod'>
                    <h1 className='font-CamptonBold achievefirstt achievee text-blue font-bold text-4xl mb-6'>
                      Achieve 100% collection rate
                    </h1>
                    <p className='font-CamptonBook achievesecondd achieveesec font-normal text-base text-grey'>
                      Avoid the tedious process of submitting monthly schedules,
                      let dedukt do the heavy lifting. We will help you process
                      your repayments and other deductions
                    </p>
                    <div class='mt-10 sm:flex sm:justify-center lg:justify-start'>
                      <div class='rounded-md lennstarted'>
                        <a
                          href='/'
                          onClick={toggleModal}
                          class='w-full flex items-center justify-center px-4 py-4 border
                   text-lg text-white startedbtnn font-normal bg-gradient-to-r 
                   from-dedukt-bl to-dedukt-br rounded md:py-4 md:text-lg lg:text-lg'
                        >
                          Get started for free
                        </a>
                      </div>
                    </div>
                    <div class='sm:flex sm:justify-end lg:justify-end -mr-72 privispacingg'>
                      <div class='rounded-md origin-bottom-right -rotate-90'>
                        <a
                          href='/'
                          onClick={selectModal}
                          class='w-full relative flex items-center justify-center px-8 py-3 border
                   text-lg text-white lenbtnn text-left font-normal bg-gradient-to-r 
                   from-dedukt-bl to-dedukt-br rounded md:py-4 md:text-lg lg:text-xl'
                        >
                          Privacy Policy
                          <img
                            class='h-5 w-5 ml-2 rotate-90'
                            src={privicon}
                            onClick={selectModal}
                            alt='terms'
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ==========================ACHIEVE ENDS ============================= */}
          {/* ==========================ACCESS STARTS ============================= */}
          <div className='py-24 accesscontt'>
            <div className=''>
              <div className='row acessspacing'>
                <div className='col-md-4 py-32 justify-content-center achievetv achieve-rggg'>
                  <div className='py-4 achievebod'>
                    <h1
                      className='font-CamptonBold text-blue accessfirstt relative 
                    font-bold text-4xl mb-6'
                    >
                      Access valid payroll data directly from registered
                      employers on Dedukt.
                    </h1>
                    <p className='font-CamptonBook font-normal accesssecondd text-base text-grey'>
                      Verify employment and income before approving loans,
                      direct from employers in nano seconds.
                    </p>
                    <div class='mt-10 sm:flex sm:justify-center lg:justify-start'>
                      <div class='rounded-md lennstartedd'>
                        <a
                          href='/'
                          onClick={toggleModal}
                          class='w-full flex items-center justify-center px-4 py-4 border
                   text-lg text-white startedbtn font-normal bg-gradient-to-r 
                   from-dedukt-bl to-dedukt-br rounded md:py-4 md:text-lg lg:text-lg'
                        >
                          Get started for free
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 accessround bg-gradient-to-r from-dedukt-bl to-dedukt-br'>
                  <div
                    className='img-fluid img-responsive d-flex 
                  '
                  >
                    <img
                      alt='achieve'
                      src={access}
                      className='w-11/12 justify-center h-full'
                    />
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
          {/* ==========================ACCESS ENDS ============================= */}
          {/* ==========================GETTING STARTED STARTS ============================= */}
          <div id='howworks' className='mb-5 mt-5'>
            <div
              className='mt-10 howimg lg:mt-0 col d-sm-block d-md-block d-lg-none d-xl-none mb-4'
              data-aos='fade-right'
              aria-hidden='true'
            >
              <img
                className='relative mx-auto studyimg  h-25 w-50 h-lg-100 h-xl-50'
                src={smallScreen}
                alt='employers'
              />
            </div>
            <div
              className='mt-10 howimg lg:mt-0 col d-sm-none d-md-none d-lg-block'
              data-aos='fade-right'
              aria-hidden='true'
            >
              <img
                className='relative mx-auto studyimg h-75 w-75 h-lg-100 h-xl-50'
                src={bigScreen}
                alt='employers'
              />
            </div>
            {/*<div className='relative pt-16 sm:pt-24 pb-16 lg:pt-52 lg:pb-48'>
              <div className='mx-auto px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl'></div>
              <div className='pr-32 pl-32 getstarted'>
                <div className='d-flex justify-content-around getstartedd'>
                  <div className='md:flex-1 relative' data-aos='fade-left'>
                    <p className='text-lg startedfirst font-bold text-black font-CamptonBold'>
                      Join our
                    </p>
                    <p className='text-lg mb-4 startedfirst font-bold text-black font-CamptonBold'>
                      ecosystem
                    </p>
                    {/*<span className='flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full'>
                    <span className='text-indigo-600'>01</span>
                  </span>
                    <a
                      href='/'
                      className='pl-4 py-2 flex relative border-l-2 border-dedukt-sky md:pl-0 md:pt-4 
                    lg:pb-6 md:pb-0 md:border-l-0 md:border-t-8 rounded'
                      aria-current='step'>
                      <span
                        className='flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 
                    bg-dedukt-sky border-dedukt-sky rounded-full'>
                        <span className='text-white font-CamptonBold font-bold text-sm-small text-2xl'>
                          1
                        </span>
                      </span>
                    </a>
                    <span
                      className='text-5xl startedsecond text-sky font-bold mb-6 
                    font-CamptonBold uppercase text-sm-small'>
                      Join
                    </span>
                  </div>

                  <div className='md:flex-1 relative' data-aos='fade-right'>
                    <p className='text-lg font-bold startedfirst text-black font-CamptonBold'>
                      Connect with
                    </p>
                    <p className='text-lg mb-4 font-bold startedfirst text-black font-CamptonBold'>
                      partners
                    </p>

                    <a
                      href='/'
                      className='pl-4 py-2 flex relative border-l-4 border-dedukt-light md:pl-0 md:pt-4 md:pb-0 
                    lg:pb-6 md:border-l-0 md:border-t-8 rounded'
                      aria-current='step'>
                      <span
                        className='flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 
                    bg-dedukt-sky border-dedukt-sky rounded-full'>
                        <span className='text-white font-CamptonBold font-bold text-sm-small text-2xl'>
                          2
                        </span>
                      </span>
                    </a>
                    <span className='text-5xl text-sky startedsecond font-bold mb-6 text-sm-small font-CamptonBold uppercase'>
                      CONNECT
                    </span>
                  </div>

                  <div className='md:flex-1 relative' data-aos='fade-left'>
                    <p className='text-lg font-bold startedfirst text-black font-CamptonBold'>
                      Set up
                    </p>
                    <p className='text-lg mb-4 font-bold startedfirst text-black font-CamptonBold'>
                      deductions
                    </p>

                    <a
                      href='/'
                      className='pl-4 py-2 flex relative border-l-4 border-dedukt-light md:pl-0 md:pt-4 md:pb-0 
                    lg:pb-6 md:border-l-0 md:border-t-8 rounded'
                      aria-current='step'>
                      <span
                        className='flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 
                    bg-dedukt-sky border-dedukt-sky rounded-full'>
                        <span className='text-white font-CamptonBold font-bold text-sm-small text-2xl'>
                          3
                        </span>
                      </span>
                    </a>
                    <span className='text-5xl text-sky startedsecond text-sm-small font-bold font-CamptonBold uppercase'>
                      DEDUCT
                    </span>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
          {/* ========================== GETTING STARTED ENDS ============================= */}

          {/*================ WHO DID WE BUILD FOR STARTS ========================*/}
          <div className=''>
            <div className='py-24 whopadding bg-gradient-to-r from-dedukt-bl to-dedukt-br rounded row'>
              <div
                className='col m-5 d-sm-none d-md-none d-lg-block'
                data-aos='fade-left'
              >
                <img className='relative w-auto' src={buildd} alt='build' />
              </div>
              <div className='col  m-lg-5 m-md-2 m-sm-1'>
                <div className=''>
                  <div className='' data-aos='fade-left'>
                    <div>
                      <p className='text-white whotext font-CamptonBold font-bold tracking-tight text-left text-4xl relative'>
                        Who did we build <br /> dedukt for?
                      </p>
                    </div>
                    <div className='mt-6'>
                      <dl className='mt-8 space-y-6'>
                        <dd className='flex'>
                          <h2 className='font-CamptonBold whotextfirst font-semibold text-left text-2xl text-white'>
                            Employees
                          </h2>
                        </dd>
                        <p className='text-base whotextsecond font-CamptonBook text-left font-normal text-white'>
                          Get access to various automated loans, savings and
                          thrift products with ease all in one place. Be in full
                          control of your finances by determining which
                          deductions come off your paycheck, monitor loan
                          repayments and other savings and thrifts.
                        </p>
                        <dd className='flex'>
                          <h2 className='font-CamptonBold font-semibold whotextfirst text-left text-2xl text-white'>
                            Employers
                          </h2>
                        </dd>
                        <p className='text-base text-left whotextsecond font-CamptonBook font-normal text-white'>
                          Whether you’re a Federal, State, Military or Private
                          Sector employer, Dedukt helps you keep track of
                          employee loan exposure and also relieves you of the
                          burden of having to offer financial help to employees
                          emergency needs, we will do it for you with ease.
                        </p>
                        <dd className='flex'>
                          <h2 className='font-CamptonBold text-left whotextfirst font-semibold text-2xl text-white'>
                            Banks & Fintechs
                          </h2>
                        </dd>
                        <p className='text-base text-left whotextsecond font-CamptonBook font-normal text-white'>
                          Connect with Employers and deliver workplace financial
                          services to your customers with simplified payroll
                          deductions
                        </p>
                      </dl>
                      <div className='mt-16'>
                        <a
                          href='/'
                          onClick={clickModal}
                          className=' bg-white btn py-4 
                        border text-center text-black btn-lg font-CircularStd-Book font-normal rounded btn-block'
                        >
                          Request a Demo
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*================ WHO DID WE BUILD FOR ENDS ========================*/}
          {/*======================= VALUES LIVE BY STARTS =========================*/}
          <div id='aboutus' className='row p-lg-5 p-md-3 p-sm-1 m-lg-5 m-md-3'>
            <div
              className='col m-5 d-sm-none d-md-none d-lg-block'
              data-aos='fade-left'
            >
              <img className='float-center' src={Deduktabout} alt='dedukt' />
            </div>

            <div className='col m-lg-5 m-md-3'>
              <div className=''>
                <div className='' data-aos='fade-up'>
                  <div>
                    <p className='text-black font-CamptonBold valuetext font-bold text-4xl relative'>
                      Values we live by
                    </p>
                  </div>
                  <div className='mt-6 valuespacing'>
                    <dl className='mt-8 space-y-6 valuespace'>
                      <dd className='flex'>
                        <h2 className='font-CamptonSemiBold valuefirst font-semibold text-2xl text-grey'>
                          Passion
                        </h2>
                      </dd>
                      <p className='text-base text-justify valuesecond font-CamptonMedium font-medium text-grey'>
                        When it comes to technology, we have the best minds but
                        it's not about writing codes, it's all about our drive
                        for success
                      </p>
                      <dd className='flex'>
                        <h2 className='font-CamptonSemiBold valuefirst font-semibold text-2xl text-grey'>
                          Commitment
                        </h2>
                      </dd>
                      <p className='text-base text-justify valuesecond font-CamptonMedium font-medium text-grey'>
                        Our customer first approach helps us understand your
                        needs better, you bet our solutions are just spot on!
                      </p>
                      <dd className='flex'>
                        <h2 className='font-CamptonSemiBold valuefirst font-semibold text-2xl text-grey'>
                          Delivery
                        </h2>
                      </dd>
                      <p className='text-base text-justify valuesecond font-CamptonMedium font-medium text-grey'>
                        We wouldn't stop until you say so but time and accuracy
                        is essential to what we do. Let's work together!
                      </p>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*======================= VALUES LIVE BY ENDS ========================= */}
          {/*======================= GROWING LIST STARTS ========================= */}
          <div className=''>
            <div className=' mx-auto py-20 px-4 sm:px-6 lg:px-8'>
              <p
                className='text-center text-4xl font-bold growingtext text-black
               font-CircularStd-Bold tracking-normal'
              >
                Join our growing list of satisfied partners
              </p>
              <div className='row w-lg-50 w-md-75 w-sm-100 mt-5 m-5'>
                <div className='col'>
                  <img
                    className='m-auto'
                    data-aos='zoom-in-right'
                    src={logo1}
                    alt='logo'
                  />
                </div>
                <div className='col'>
                  <img
                    className='m-auto'
                    data-aos='zoom-in-right'
                    src={logo2}
                    alt='Checkoff'
                  />
                </div>
                <div className='col'>
                  <img
                    className='m-auto'
                    data-aos='zoom-in-left'
                    src={logo3}
                    alt='liberty'
                  />
                </div>
                <div className='col'>
                  <img
                    className='m-auto'
                    data-aos='zoom-in-left'
                    src={logo4}
                    alt='terms'
                  />
                </div>
              </div>
              <div className='mt-16 ml-1 text-center'>
                <a
                  href='/'
                  onClick={clickModal}
                  className='inline-flex bg-gradient-to-r from-dedukt-bl to-dedukt-br 
                        pr-80 pl-80 py-4 
                        border text-base reqdemobtn text-center text-white font-CamptonMedium 
                        font-normal rounded'
                >
                  {" "}
                  Request a Demo{" "}
                </a>
              </div>
            </div>
          </div>
          {/*======================= GROWING LIST ENDS ========================= */}
          {/* ==========================GET SUPPORT STARTS ============================= */}
          <div id='support' className='holdContainer'>
            <div className='support-padding'>
              <div className='container'>
                <div className='support-text'>
                  <h1 className='text-black font-bold tracking-normal supptfirst font-CamptonBold text-4xl'>
                    How can we help you <br />
                    today?
                  </h1>
                  <p className='mt-4 mb-3 font-grey font-normal supptsecond font-CircularStd-Book text-base'>
                    We’re all ears. Drop a message and we'll get back to <br />
                    you as soon as possible
                  </p>
                </div>
                <div className='support-logo'>
                  <form
                    className='form-contt shadow-md pt-2 justify-content-center'
                    onSubmit={sendEmail}
                  >
                    <div className='row fom-row'>
                      <div className='col-md-6 mt-4 form-text form-namee'>
                        <label className='form-label font-CamptonMedium text-sm text-grey font-medium'>
                          Full Name
                        </label>
                        <input
                          required
                          value={messageData.name}
                          onChange={(e) =>
                            messageOnchange("name", e.target.value)
                          }
                          className='form-control border border-gradient-to-r from-dedukt-blue to-dedukt-sk rounded'
                          placeholder=''
                        />
                      </div>
                      <div className='col-md-6 mb-4 mt-4 form-text form-maill'>
                        <label className='form-label font-CamptonMedium text-sm text-grey font-medium'>
                          Email Address
                        </label>
                        <input
                          required
                          type='email'
                          value={messageData.email}
                          onChange={(e) =>
                            messageOnchange("email", e.target.value)
                          }
                          className='form-control border border-gradient-to-r from-dedukt-blue to-dedukt-sk rounded'
                          placeholder=''
                        />
                      </div>

                      <div className='col-md-6 mb-5 form-text justify-content-center form-msgg'>
                        <label
                          htmlFor='exampleFormControlTextarea1'
                          className='form-label font-CamptonMedium text-sm text-grey font-medium'
                        >
                          Message
                        </label>
                        <textarea
                          required
                          className='form-control border-gradient-to-r from-dedukt-blue to-dedukt-sk rounded border'
                          value={messageData.message}
                          onChange={(e) =>
                            messageOnchange("message", e.target.value)
                          }
                          id='exampleFormControlTextarea1'
                          rows='6'
                          placeholder=''
                        ></textarea>
                      </div>
                    </div>
                    <div className='col-md-6 mb-3 form-text text-center'>
                      <button
                        type='submit'
                        class='btn-lg form-btnn bg-gradient-to-r from-dedukt-bl to-dedukt-br'
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* ==========================GET SUPPORT ENDS ============================= */}

          {/*======================= FOOTER STARTS ========================= */}
          <div className=''>
            <footer className='bg-grayy bg-ellipses'>
              <div class='grid grid-cols-2 pb-2 gap-8 md:grid-cols-2 lg:grid-cols-2'>
                <div class='col-span-1 flex deduktt items-start md:col-span-2 lg:col-span-1'>
                  <img
                    className='block pl-16 deduktlogoo w-6/12 h-full pt-20 pr-8 xl:pr-16 xl:pl-32 sm:px-0'
                    src={logoo}
                    alt='dedukt'
                  />
                </div>
                <div class='col-span-1 flex flex-row justify-center md:col-span-2 lg:col-span-1'>
                  <div className='relative pt-20'>
                    <div class='relative col-span-2 flex flex-row justify-between md:col-span-3 lg:col-span-1'>
                      <span className='text-white text-lg mr-3 followtext font-normal font-CircularStd-Book relative'>
                        Follow us on
                      </span>
                      <img
                        class='h-auto iconn'
                        src={linkedin}
                        alt='terms'
                        href='https://www.linkedin.com/company/dedukt/'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class='max-w-7xl mx-auto pt-2 pb-12 px-4 overflow-hidden sm:px-6 xl:px-8'>
                <div class='mt-8 flex justify-center w-full border-t border-white space-x-6'></div>

                <p class='mt-8 text-center footertext text-lg font-CircularStd-Book font-normal text-white'>
                  &copy; {date} Equal Digital Nigeria Limited | All Rights
                  Reserved
                </p>
              </div>
            </footer>
          </div>
          {/*======================= FOOTER ENDS ========================= */}
          {/* ==========================GET STARTED MODAL STARTS ============================= */}

          <Modal
            size='md'
            isOpen={signUpModal}
            centered
            backdrop='static'
            keyboard={false}
          >
            <div className='d-flex justify-content-center text-center'>
              <ModalBody>
                <div className='font-size-24 font-weight-700'>
                  Dedukt connects employers and lenders digitally
                </div>
                <div>
                  <a
                    href={url + "lender"}
                    target='_blank'
                    rel='noreferrer'
                    className='btn w-100 font-size-18 font-weight-400 mt-5'
                    style={{ backgroundColor: "#FAFAFA" }}
                  >
                    <span className='active-dot'></span> I am a Lender
                  </a>
                </div>
                <div>
                  <a
                    href={url + "employer"}
                    to='/employer'
                    target='_blank'
                    rel='noreferrer'
                    className='btn w-100 font-size-18 font-weight-400 mt-2 mb-4'
                    style={{ backgroundColor: "#FAFAFA" }}
                  >
                    <span className='active-dot'></span> I am an Employer
                  </a>
                </div>
              </ModalBody>
            </div>
            <ModalFooter>
              <button className='btn btn-sign' onClick={toggleModal}>
                Close
              </button>
            </ModalFooter>
          </Modal>
          {/* ==========================GET STARTED MODAL ENDS ============================= */}
          {/* ==========================REQUEST DEMO MODAL STARTS ============================= */}

          <Modal
            size='md'
            isOpen={reqDemoModal}
            centered
            backdrop='static'
            keyboard={false}
          >
            <div className='d-flex p-16 justify-content-center text-center'>
              <ModalBody>
                <form onSubmit={sendEmail} className='modalbdy'>
                  <div className='sm:block modallbdy absolute -top-24 -right-8 pt-4 pr-4'>
                    <button
                      type='button'
                      className='bg-white rounded-full text-white'
                      onClick={clickModal}
                    >
                      <span className='sr-only'>Close</span>
                      <XIcon
                        className='h-6 w-6 bg-gradient-to-r from-dedukt-bl to-dedukt-br rounded-full'
                        aria-hidden='true'
                      />
                    </button>
                  </div>

                  <div className='font-CamptonMedium font-medium reqdemotext text-center text-black text-2xl sm:text-xl'>
                    Kindly fill in the following information, <br />
                    we’d reach out to you within 48 hours.
                  </div>
                  <div className='col-md-6 mt-4 form-text form-req'>
                    <label
                      className='form-label font-CamptonMedium text-xl 
                          text-black font-medium'
                    >
                      Name
                    </label>
                    <input
                      required
                      value={messageData.name}
                      onChange={(e) => messageOnchange("name", e.target.value)}
                      className='form-control border-2 border-black rounded'
                      placeholder=''
                    />
                  </div>
                  <div className='col-md-6 mt-4 form-text form-comp'>
                    <label
                      className='form-label font-CamptonMedium text-xl 
                          text-black font-medium'
                    >
                      Company Name
                    </label>
                    <input
                      required
                      value={messageData.company}
                      onChange={(e) =>
                        messageOnchange("company", e.target.value)
                      }
                      className='form-control border-2 border-black rounded'
                      placeholder=''
                    />
                  </div>
                  <div className='col-md-6 mt-4 form-text form-maila'>
                    <label
                      className='form-label font-CamptonMedium text-xl 
                          text-black font-medium'
                    >
                      Email Address
                    </label>
                    <input
                      required
                      type='email'
                      value={messageData.email}
                      onChange={(e) => messageOnchange("email", e.target.value)}
                      className='form-control border-2 border-black to-dedukt-sk rounded'
                      placeholder=''
                    />
                  </div>
                  <div className='col-md-6 mt-4 form-text form-maila'>
                    <label
                      className='form-label font-CamptonMedium text-xl 
                          text-black font-medium'
                    >
                      Phone Number
                    </label>
                    <input
                      required
                      value={messageData.phonenumber}
                      onChange={(e) =>
                        messageOnchange("phonenumber", e.target.value)
                      }
                      className='form-control border-2 border-black rounded'
                      placeholder=''
                    />
                  </div>
                  <button
                    className='mt-5 btn-lg form-btnreq text-base bg-gradient-to-r from-dedukt-blue to-dedukt-sk'
                    type='submit'
                  >
                    Request a Demo
                  </button>
                </form>
              </ModalBody>
            </div>
          </Modal>
          {/* ==========================REQUEST DEMO MODAL ENDS ============================= */}
          {/* ==========================PRIVACY POLICY MODAL STARTS ============================= */}
          <Modal
            size='md'
            isOpen={privacyDemoModal}
            centered
            backdrop='static'
            keyboard={false}
          >
            <div className='d-flex justify-content-center text-center'>
              <ModalBody>
                <div className='sm:block absolute -top-8 -right-7 pt-4 pr-4'>
                  <button
                    type='button'
                    className='bg-white rounded-full text-white'
                    onClick={selectModal}
                  >
                    <span className='sr-only'>Close</span>
                    <XIcon
                      className='h-6 w-6 bg-gradient-to-r from-dedukt-bl to-dedukt-br rounded-full'
                      aria-hidden='true'
                    />
                  </button>
                </div>

                <div className='font-CamptonMedium font-bold text-left text-black text-xl'>
                  Privacy Policy
                </div>
                {/*<div className=' mt-4'>
                  <h1 class='font-CamptonMedium font-bold text-left text-black text-sm'>
                    1. INTRODUCTION
                  </h1>
                </div>*/}
                <div className='mt-4'>
                  <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
                    Equal Digital Limited has created this Privacy Policy to
                    explain how Equal Digital Limited collects, uses, discloses
                    and protects your Personal Data, including any other
                    information.
                  </p>
                  <p className='mt-4 font-CamptonBook font-normal text-left text-black text-xs'>
                    You accept this Privacy Policy and hereby give Equal Digital
                    Limited consent to save, process and use your Personal Data
                    to the extent as allowed by law when you provide us with
                    details of your Personal Data or by clicking on the “I
                    accept” button. You have the right to withdraw your consent
                    at any time, provided that we do not have another legal
                    basis to keep processing your Personal Data.
                  </p>
                  {/*<p className='mt-2 font-CamptonBook font-normal text-left text-black text-xs'>
                    We may change this Privacy Policy from time to time. If we
                    make changes, we will notify you by revising the date at the
                    top of this policy, and in some cases, we may provide you
                    with additional notice (such as by adding a statement to the
                    homepages of our website or [mobile application] or by
                    sending you an email notification).
                  </p>
                  <p className='mt-2 font-CamptonBook font-normal text-left text-black text-xs'>
                    We encourage you to review the Privacy Policy whenever you
                    interact with us to stay informed about our information
                    practices and the ways you can help protect your privacy.
                  </p>*/}
                </div>
                {readMore && extraContent}
                <button
                  className='mt-5 mr-4 privacy-btnreq btn-lg font-CircularStd-Book text-base text-white text-center
                  bg-dedukt'
                  type='submit'
                  onClick={selectModal}
                >
                  I accept
                </button>
                <button
                  className='mt-4 mr-4 btn-lg privacy-btnreq font-CircularStd-Book text-base text-dedukt-blue 
                  bg-gradient-to-r 
              from-dedukt-gl to-dedukt-gr'
                  onClick={() => {
                    setReadMore(!readMore);
                  }}
                >
                  {linkName}
                </button>
              </ModalBody>
            </div>
          </Modal>
          {/* ==========================PRIVACY POLICY MODAL ENDS ============================= */}
        </>
      )}
    </Disclosure>
  );
};

export default Lender;
